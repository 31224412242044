<template>
  <b-modal
    id="record-branch-summary-madal"
    ref="record-branch-summary-madal"
    no-close-on-backdrop
    hide-footer
    :title="title"
    @show="resetModal"
    @hidden="resetModal"
  >

    <div class="p-2">

      <b-form-group
        label="จำนวน"
      >
        <b-form-input
          v-model="amount"
          type="number"
          size="xl"
          placeholder="ใส่จำนวนเงิน"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รายละเอียด"
      >
        <b-form-textarea
          v-model="description"
          type="text"
          placeholder="ใส่รายละเอียดเพิ่มเติม"
        ></b-form-textarea>
      </b-form-group>

      <b-form-file
        ref="inputFile"
        v-model="file"
        class="mt-1"
        size="sm"
        placeholder="แนบไฟล์รูป"
        browse-text="เลือกรูป"
        accept="image/jpeg, image/png"
      ></b-form-file>

    </div>

    <div class="d-flex justify-content-end p-2">
      <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
      <b-button :disabled="isProcess" type="submit" variant="primary" size="sm" @click="handleSubmit">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
    </div>
  </b-modal>
</template>
<script>
import RecordService from '@/services/RecordService'

import Swal from 'sweetalert2'

export default {
  name: 'RecordSummaryModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      amount: null,
      description: null,
      file: null
    }
  },
  computed: {
    title() {
      return this.data?.name
    },
    AccountId() {
      return this.$route.params?.accountId || null
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = null
      this.amount = this.data.amount
      this.description = this.data.note
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.isProcess = false
    },
    showModal() {
      this.$refs['record-branch-summary-madal'].show()
    },
    hideModal() {
      this.selected = null
      this.amount = null
      this.description = null
      this.$refs['record-branch-summary-madal'].hide()
    },
    handleSubmit() {

      if(!this.amount && this.amount!==0) {
        return Swal.fire({
          title: 'กรุณาระบุจำนวน',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      const amount = Math.abs(this.amount)
      this.input = {
        ...this.data,
        amount: parseFloat(`${this.data.prefix}${amount}`),
        note: this.description
      }

      this.save()
    },
    save() {
      this.isProcess = true

      let formData = new FormData()
      for (const [key, value] of Object.entries(this.input)) {
        formData.append(key, (value||''))
      }

      if(this.file) {
        formData.append('image', this.file)
      }

      RecordService.saveSummary(formData, this.AccountId)
      .then((response)=>{
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#record-branch-summary-madal {

  input[type=text] {
    color: blue;
  }

  .modal-dialog {
    .modal-body {
      padding: 0;
    }
  }
}
</style>
