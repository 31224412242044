<template>
  <b-modal
    id="record-agent-madal"
    ref="record-agent-madal"
    no-close-on-backdrop
    hide-footer
    :title="title"
    @show="resetModal"
    @hidden="resetModal"
    size="lg"
  >
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th class="text-center">หวย</th>
          <th colspan="3" class="text-center">ยอดเว็บ</th>
          <!-- <th v-if="isManager" colspan="3"></th> -->
        </tr>
      </thead>
      <tbody v-for="(group, gKey) in groups" :key="gKey">
        <tr>
          <th class="text-info text-center">{{group[0].group.item_name}}</th>
          <td :class="isManager?'td-manager':'td-member'" class="text-center">ยอดขาย</td>
          <td :class="isManager?'td-manager':'td-member'" class="text-center">ยอดจ่าย</td>
          <td :class="isManager?'td-manager':'td-member'" class="text-center">คงเหลือ</td>
        </tr>
        <tr v-for="(item, key) in group" :key="key">
          <td class="text-center">{{item.item_name}}</td>
          <td>
            <b-form-input v-model.number="input.markets[item._id].amount.sales" @update="input.markets[item._id].amount.balance=(input.markets[item._id].amount.sales-input.markets[item._id].amount.won)" :number="true" v-on:keydown="keypress" placeholder="กรอกตัวเลข" size="sm" class="text-right" :class="numberClass(input.markets[item._id].amount.sales)"></b-form-input>
          </td>
          <td>
            <b-form-input v-model.number="input.markets[item._id].amount.won" @update="input.markets[item._id].amount.balance=(input.markets[item._id].amount.sales-input.markets[item._id].amount.won)" :number="true" v-on:keydown="keypress" placeholder="กรอกตัวเลข" size="sm" class="text-right text-danger"></b-form-input>
          </td>
          <td>
            <b-form-input :value="input.markets[item._id].amount.balance" :number="true" v-on:keydown="keypress" placeholder="กรอกตัวเลข" size="sm" class="text-right" :class="numberClass(input.markets[item._id].amount.balance)" readonly></b-form-input>
          </td>
        </tr>
        <tr v-if="groupsLength > 1 && group.length > 1">
          <th>รวม {{group[0].group.item_name}}</th>
          <th class="text-right" v-html="numberFormat(totalGroups[gKey].sales)"></th>
          <th class="text-right" v-html="numberFormat(totalGroups[gKey].won)"></th>
          <th class="text-right" v-html="numberFormat(totalGroups[gKey].balance)"></th>
        </tr>
      </tbody>
      <tfoot style="border-top: 3px double rgb(204, 204, 204);">
        <tr style="font-size: 120%;">
          <th class="text-center">รวม</th>
          <th class="text-right"><span class="pr-2" v-html="numberFormat(totalSale)"></span></th>
          <th class="text-right"><span class="pr-2" v-html="numberFormat(totalWon)"></span></th>
          <th class="text-right"><span class="pr-2" v-html="numberFormat(totalBalance)"></span></th>
        </tr>
      </tfoot>
    </table>

    <div v-if="!isManager" class="mt-6">
      <strong>สรุปค่าคอมฯ</strong>
      <table class="table table-sm table-bordered table-warning">
        <tr>
          <th width="50%" class="text-center">รายการ</th>
          <th class="text-center">จำนวน</th>
        </tr>
        <tr>
          <td class="text-center">ถูกรางวัล</td>
          <td>
            <b-form-input
              :number="true"
              v-on:keydown="keypress"
              v-on:blur="keyup"
              placeholder="กรอกตัวเลข"
              class="text-right px-2"
              :class="numberClass(agentWon)"
              v-model="agentWon"
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td class="text-center">คอมฯ</td>
          <td>
            <b-form-input
              :number="true"
              v-on:keydown="keypress"
              v-on:blur="keyup"
              placeholder="กรอกตัวเลข"
              class="text-right px-2"
              :class="numberClass(agentCommission)"
              v-model="agentCommission"
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td class="text-center">รวม</td>
          <td>
            <b-form-input :number="true" v-on:keydown="keypress" placeholder="กรอกตัวเลข" class="text-right px-2" :class="numberClass(agentTotal)" :value="agentTotal" :disabled="true"></b-form-input>
          </td>
        </tr>
      </table>
    </div>

    <div v-if="isManager" class="mt-6">
      <strong class="text-primary">สรุปค่าคอมฯ</strong>
      <table class="table table-sm table-bordered table-warning">
        <thead>
          <tr>
            <th width="40%" class="text-center">รายการ</th>
            <th width="30%" class="text-center">เอเย่นต์ ({{agentUsername}})</th>
            <th width="30%" class="text-center">บริษัท</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">ถือหุ้น</td>
            <td>
              <b-form-input
                :number="true"
                v-on:keydown="keypress"
                v-on:blur="keyup"
                placeholder="กรอกตัวเลข"
                class="text-right px-2"
                :class="numberClass(agentHold)"
                v-model="agentHold"
              ></b-form-input>
            </td>
            <td>
              <b-form-input
                :number="true"
                class="text-right px-2"
                :class="numberClass(ownerHold)"
                :value="ownerHold"
                :disabled="true"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <td class="text-center">ถูกรางวัล</td>
            <td>
              <b-form-input
                :number="true"
                v-on:keydown="keypress"
                v-on:blur="keyup"
                placeholder="กรอกตัวเลข"
                class="text-right px-2"
                :class="numberClass(agentWon)"
                v-model="agentWon"
              ></b-form-input>
            </td>
            <td>
              <b-form-input
                :number="true"
                class="text-right px-2"
                :class="numberClass(ownerWon)"
                :value="ownerWon"
                :disabled="true"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <td class="text-center">คอมฯ</td>
            <td>
              <b-form-input
                :number="true"
                v-on:keydown="keypress"
                v-on:blur="keyup"
                placeholder="กรอกตัวเลข"
                class="text-right px-2"
                :class="numberClass(agentCommission)"
                v-model="agentCommission"
              ></b-form-input>
            </td>
            <td>
              <b-form-input
                :number="true"
                class="text-right px-2"
                :class="numberClass(ownerCommission)"
                :value="ownerCommission"
                :disabled="true"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <td class="text-center">รวม</td>
            <td>
              <b-form-input :number="true" v-on:keydown="keypress" placeholder="กรอกตัวเลข" class="text-right px-2" :class="numberClass(agentTotal)" :value="agentTotal" :disabled="true"></b-form-input>
            </td>
            <td>
              <b-form-input :number="true" v-on:keydown="keypress" placeholder="กรอกตัวเลข" class="text-right px-2" :class="numberClass(ownerTotal)" :value="ownerTotal" :disabled="true"></b-form-input>
            </td>
          </tr>
        </tbody>
      </table>

      <strong class="text-primary">สรุป ได้-เสีย</strong>
      <table class="table table-sm table-bordered table-primary">
        <tbody>
          <tr>
            <th width="70%" class="text-center text-primary" style="vertical-align: middle;">Company</th>
            <td>
              <b-form-input
                :number="true"
                v-on:keydown="keypress"
                v-on:blur="keyup"
                placeholder="กรอกตัวเลข"
                class="text-right px-2"
                :class="numberClass(companyWinloss)"
                v-model="companyWinloss"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th class="text-center text-primary" style="vertical-align: middle;">ได้-เสีย สุทธิ</th>
            <td>
              <b-form-input
                :number="true"
                v-on:keydown="keypress"
                v-on:blur="keyup"
                class="text-right px-2"
                :class="numberClass(ownerWinloss)"
                :value="ownerWinloss"
                :disabled="true"
              ></b-form-input>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr>
    <div class="d-flex justify-content-end p-2">
      <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
      <b-button :disabled="isProcess" type="submit" variant="primary" size="sm" @click="handleSubmit">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
    </div>
  </b-modal>
</template>
<script>
import AppConfig from '@/configs/app.config.js'

import RecordService from '@/services/RecordService'
import Swal from 'sweetalert2'

import _ from 'lodash'

export default {
  name: 'RecordAgentModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    markets: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      input: {},
      isProcess: false,
      needReload: false,
      agentHold: 0,
      agentWon: 0,
      agentCommission: 0,
      companyWinloss: 0
    }
  },
  computed: {
    groups() {
      if(!this.data)
        return []

      const markets = this.markets.filter((market)=>{
        return this.input.markets[market._id]
      })

      const sortMarkets = _.sortBy(markets, (market)=>{
        return parseInt(market.group.sort||0)
      })

      return _.groupBy(sortMarkets, 'owners.mGroupId')
    },
    isManager() {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      return /*LoggedInData?.userRole === 'Owner' ||*/ LoggedInData?.role === 'Manager'
    },
    title() {
      return `เว็บ: ${this.data?.web?.item_name} - เอเย่นต์: ${this.data?.agent?.item_name}`
    },
    agentUsername() {
      return this.data?.agent?.item_name
    },
    totalGroups() {
      let totals = {}

      for (const gkey in this.groups) {
        totals[gkey] = this.groups[gkey].reduce((total, market)=>{
          total.sales += this.decimalNumber(parseFloat(this.input.markets[market._id].amount.sales||0))
          total.won -= this.decimalNumber(parseFloat(this.input.markets[market._id].amount.won||0))
          total.balance += this.decimalNumber(parseFloat(this.input.markets[market._id].amount.balance||0))
          return total
        }, {
          sales: 0,
          won: 0,
          balance: 0
        })
      }

      return totals
    },
    totalSale() {
      let total = 0
      for (const key in this.input.markets) {
        total += this.decimalNumber((parseFloat(this.input.markets[key].amount.sales || 0)))
      }
      return total
    },
    totalWon() {
      let total = 0
      for (const key in this.input.markets) {
        total -= this.decimalNumber(Math.abs(parseFloat(this.input.markets[key].amount.won || 0)))
      }
      return total
    },
    totalBalance() {
      let total = 0
      for (const key in this.input.markets) {
        total += this.decimalNumber(parseFloat(this.input.markets[key].amount.balance || 0))
      }
      return total
    },
    ownerHold() {
      return this.decimalNumber(this.totalSale - this.agentHold)
    },
    ownerWon() {
      return this.decimalNumber(this.totalWon - this.agentWon)
    },
    ownerCommission() {
      return -this.agentCommission
    },
    agentTotal() {
      return this.decimalNumber(this.agentHold + Math.abs(parseFloat(this.agentCommission || 0)) + parseFloat(this.agentWon || 0))
    },
    ownerTotal() {
      return this.decimalNumber(this.ownerHold + this.ownerWon + this.ownerCommission)
    },
    ownerWinloss() {
      return this.decimalNumber((this.ownerTotal||0) - (this.companyWinloss||0))
    },
    AccountId() {
      return this.$route.params?.accountId || null
    },
    groupsLength() {
      let size = 0
      for (const key in this.groups) {
        if (this.groups.hasOwnProperty(key)) size++;
      }
      return size;
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data.agent))
      this.agentHold = this.input?.amount?.agentHold || 0
      this.agentWon = this.input?.amount?.agentWon || 0
      this.agentCommission = this.input?.amount?.agentCommission || 0
      this.companyWinloss = this.input?.amount?.companyWinloss || 0
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.isProcess = false
    },
    showModal() {
      this.$refs['record-agent-madal'].show()
    },
    hideModal() {
      this.$refs['record-agent-madal'].hide()
    },
    handleSubmit() {
      this.input.date = this.data.date
      this.input.amount = {
        /**
         * ยอดขายรวม
         */
        sales: this.totalSale,
        /**
         * ยอดจ่ายรวม
         */
        won: this.totalWon,
        /**
         * คงเหลือรวม
         */
        balance: this.totalBalance,

        agentHold: this.agentHold,
        agentWon: parseFloat(this.agentWon),
        agentCommission: parseFloat(this.agentCommission),
        agentTotal: this.agentTotal,

        ownerHold: this.ownerHold,
        ownerWon: this.ownerWon,
        ownerCommission: this.ownerCommission,
        ownerTotal: this.ownerTotal,

        ownerWinloss: this.ownerWinloss,
        companyWinloss: this.companyWinloss
      }

      // console.log(this.data.agent?._id)
      if(this.data.agent?._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      this.isProcess = true
      RecordService.save(this.input, this.AccountId)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    update() {
      this.isProcess = true
      RecordService.update(this.data.agent._id, this.input, this.AccountId)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    numberClass(number) {
      if(!number || number==='-')
        return ''

      number = parseFloat(number)
      if(number < 0)
        return 'text-danger'

      if(number > 0)
        return 'text-success'

      return ''
    },
    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },
    decimalNumber(number, dec=2) {
      if(!parseFloat(number))
        return 0

      return parseFloat(number.toFixed(dec))
    },
    keypress(event) {
      if(['Space'].includes(event.code)) {
        event.preventDefault()
      }
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', '-', '.', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
        event.preventDefault()
      }
    },
    keyup(event) {
      if(event.target.value===''||!parseFloat(event.target.value))
        event.target.value = 0

      event.target.value = parseFloat(event.target.value||0)
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#record-agent-madal {

  input[type=number] {
    color: blue;
  }

  .modal-xl {
    // max-width: 800px;

    .modal-body {
      padding: 0;

      .table {
        th {
          // width: 20%;
          text-align: center;
        }
        td {
          vertical-align: middle;
        }
      }
    }
  }

  .td-manager {
    width: 25%;
  }
  .td-member {
    width: 25%;
  }

  .table {
    td {
      vertical-align: middle;
    }
  }

  .table-sum {
    margin-top: 30px;
    td {
      border-top: 0;
    }
  }

  tfoot {
    th {
      vertical-align: middle;
    }
  }
}
</style>
