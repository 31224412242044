<template>
  <div id="daily-record" class="container-fluid">
    <div>
      <h3>{{AccountName}}</h3>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h4 class="text-primary m-0">บันทึกรายงานวันที่</h4>
        <div class="pl-3 pb-1">
          <div role="group" class="input-group mb-2 mr-sm-2 mb-sm-0">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="far fa-calendar-alt"></i></div>
            </div>
            <flat-pickr
              :config="datepickerConfig"
              v-model="date"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div>
        <span :class="reportStatus.class"><i :class="reportStatus.icon"></i> {{ reportStatus.text }}</span>
      </div>
    </div>

    <div>ยอดคีย์ตามเว็บ</div>
    <div class="card">
      <table class="table table-sm table-info table-bordered mb-0" v-for="(web, index) in Websites" :key="index">
        <thead>
          <tr>
            <th :colspan="isManager ? 15 : 6" style="background-color: #eee">เว็บ <span class="text-primary">{{web.item_name}}</span></th>
          </tr>
          <tr>
            <th rowspan="2" width="17%" class="text-center">รหัสเอเย่นต์</th>
            <th :colspan="isManager ? 3 : 4" width="18%" class="text-center">ยอดเว็บ</th>
            <th v-if="isManager" colspan="4" width="24%" class="text-center">เอเย่นต์</th>
            <th v-if="isManager" colspan="4" width="24%" class="text-center">บริษัทฯ</th>
            <th v-if="isManager" colspan="2" width="12%" class="text-center">สรุป ได้-เสีย</th>
            <th rowspan="2" width="5%" class="text-center"></th>
          </tr>
          <tr>
            <td width="6%" class="text-center">ยอดขาย</td>
            <td width="6%" class="text-center">ยอดจ่าย</td>
            <td width="6%" class="text-center">คงเหลือ</td>
            <td v-if="!isManager" width="6%" class="text-center">ค่าคอมฯ</td>

            <td v-if="isManager" width="6%" class="text-center">ถือหุ้น</td>
            <td v-if="isManager" width="6%" class="text-center">ถูกรางวัล</td>
            <td v-if="isManager" width="6%" class="text-center">คอมฯ</td>
            <td v-if="isManager" width="6%" class="text-center">รวม</td>

            <td v-if="isManager" width="6%" class="text-center">ถือหุ้น</td>
            <td v-if="isManager" width="6%" class="text-center">ถูกรางวัล</td>
            <td v-if="isManager" width="6%" class="text-center">คอมฯ</td>
            <td v-if="isManager" width="6%" class="text-center">รวม</td>
            <td v-if="isManager" width="6%" class="text-center">Company</td>
            <td v-if="isManager" width="6%" class="text-center">สุทธิ</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(agent, aIndex) in web.agents" :key="aIndex">
            <td class="font-weight-bold text-info text-center">{{agent.item_name}}</td>
            <td class="text-right" v-html="numberFormat(agent.amount.sales)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.won)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.balance)"></td>
            <td v-if="!isManager" class="text-right" v-html="numberFormat(agent.amount.agentCommission)"></td>

            <td v-if="isManager" class="text-right" v-html="numberFormat(agent.amount.agentHold)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(agent.amount.agentWon)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(agent.amount.agentCommission)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(agent.amount.agentTotal)"></td>

            <td v-if="isManager" class="text-right" v-html="numberFormat(agent.amount.ownerHold)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(agent.amount.ownerWon)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(agent.amount.ownerCommission)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(agent.amount.ownerTotal)"></td>

            <td v-if="isManager" class="text-right" v-html="numberFormat(agent.amount.companyWinloss)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(agent.amount.ownerWinloss)"></td>

            <td class="text-center">
              <button class="btn btn-link btn-sm p-0" @click="toggleAgentData(web, agent)">แก้ไข</button>
            </td>
          </tr>
          <tr v-if="!web.agents.length">
            <td colspan="15" class="text-center bg-light">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="web.agents.length">
          <tr>
            <th class="text-center">รวม</th>
            <th class="text-right" v-html="numberFormat(web.amount.sales)"></th>
            <th class="text-right" v-html="numberFormat(web.amount.won)"></th>
            <th class="text-right" v-html="numberFormat(web.amount.balance)"></th>
            <td v-if="!isManager" class="text-right" v-html="numberFormat(web.amount.agentCommission)"></td>

            <td v-if="isManager" class="text-right" v-html="numberFormat(web.amount.agentHold)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(web.amount.agentWon)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(web.amount.agentCommission)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(web.amount.agentTotal)"></td>

            <td v-if="isManager" class="text-right" v-html="numberFormat(web.amount.ownerHold)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(web.amount.ownerWon)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(web.amount.ownerCommission)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(web.amount.ownerTotal)"></td>

            <td v-if="isManager" class="text-right" v-html="numberFormat(web.amount.companyWinloss)"></td>
            <td v-if="isManager" class="text-right" v-html="numberFormat(web.amount.ownerWinloss)"></td>

            <th class="text-right" colspan="1"></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <span>สาเหตุยอด ขาด/เกิน</span>
      <button class="btn btn-sm btn-link" @click="addItem">เพิ่ม</button>
    </div>
    <div class="card">
      <table class="table table-sm table-warning table-bordered mb-0">
        <thead>
          <tr>
            <th style="width: 30%;">รายการ</th>
            <th style="width: 15%;" class="text-center">จำนวน</th>
            <th style="width: 20%;" class="text-center">ผู้บันทึก</th>
            <th class="text-center">รายละเอียด</th>
            <th class="text-center" style="width: 8%">แก้ไข</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in Others" :key="item._id">
            <td class="text-info">{{ item.item_name }}</td>
            <td class="text-right text-success" v-html="numberFormat(item.amount.number)"></td>
            <td class="text-center">{{item.logs.by || '-'}}</td>
            <td class="text-center">{{ item.description || '-' }}</td>
            <td class="text-center"><button class="btn btn-link btn-sm p-0" @click="editItem(item)">แก้ไข</button>/<button class="btn btn-link btn-sm p-0 text-danger" @click="deleteItem(item)">ลบ</button></td>
          </tr>
          <tr v-if="!Others.length">
            <td colspan="5" class="text-center bg-light">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="Others.length">
          <tr>
            <th class="text-center">รวม ขาด/เกิน</th>
            <th class="text-right text-success" v-html="numberFormat(totalOther)"></th>
            <th colspan="2"></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <div>
      <h5 class="text-info">สรุปยอดประจำวัน</h5>
    </div>
    <div class="card">
      <table v-if="!isManager" class="table table-sm mb-0 table-success table-bordered">
        <thead>
          <tr>
            <th style="width: 25%;">รายการ</th>
            <th style="width: 20%;" class="text-right">จำนวน</th>
            <th style="width: 20%;" class="text-center">ผู้บันทึก</th>
            <th class="text-center">รายละเอียด</th>
            <th style="width: 8%"></th>
          </tr>
        </thead>
        <tbody>
          <tr style="font-weight: bold; font-size: 120%;">
            <td>เงินตั้งต้น</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(startAmount)"></td>
            <td class="text-center">{{ saveStartAmountBy }}</td>
            <td class="text-center"><small>{{ saveStartNote }}</small></td>
            <td class="text-center">
              <button class="btn btn-link btn-sm p-0" @click="editSummary('start')">แก้ไข</button>
            </td>
          </tr>
          <tr>
            <td>ยอดขาย</td>
            <td class="text-right" v-html="numberFormat(totalSale)"></td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td>ยอดจ่าย</td>
            <td class="text-right" v-html="numberFormat(totalWon)"></td>
            <td colspan="3"></td>
          </tr>
          <tr style="font-weight: bold; font-size: 120%;">
            <td>เงินที่ควรจะมี</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(lottoBalance)"></td>
            <td colspan="3"></td>
          </tr>

          <tr>
            <td colspan="5" style="height: 35px;"></td>
          </tr>
          <tr>
            <td>ขาด/เกิน</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(totalOther)"></td>
            <td colspan="3"></td>
          </tr>
          <tr style="font-weight: bold; font-size: 120%;">
            <td>เงินที่มีจริง</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(realBalance)"></td>
            <td colspan="3"></td>
          </tr>

          <tr>
            <td colspan="5" style="height: 35px;"></td>
          </tr>

          <tr style="font-size: 120%;">
            <td>โอนกลับบัญชีกลาง</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(refundAmount)"></td>
            <td class="text-center">{{ saveRefundAmountBy }}</td>
            <td class="text-center"><small>{{ saveRefundNote }}</small></td>
            <td class="text-center">
              <button class="btn btn-link btn-sm p-0" @click="editSummary('refund')">แก้ไข</button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr style="font-size: 180%;">
            <th>คงเหลือ</th>
            <th class="text-right font-weight-bold" v-html="numberFormat(grandTotal, '0')"></th>
            <td colspan="3"></td>
          </tr>
        </tfoot>
      </table>

      <table v-if="isManager" class="table table-sm mb-0 table-success table-bordered">
        <thead>
          <tr>
            <th style="width: 25%;">รายการ</th>
            <th style="width: 20%;" class="text-right">จำนวน</th>
            <th style="width: 20%;" class="text-center">ผู้บันทึก</th>
            <th class="text-center">รายละเอียด</th>
          </tr>
        </thead>
        <tbody>
          <tr style="font-weight: bold; font-size: 120%;">
            <td>เงินตั้งต้น</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(startAmount)"></td>
            <td class="text-center">{{ saveStartAmountBy }}</td>
            <td class="text-center"><small>{{ saveStartNote }}</small></td>
          </tr>
          <tr>
            <td>ยอดขาย</td>
            <td class="text-right" v-html="numberFormat(totalSale)"></td>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td>ยอดจ่าย</td>
            <td class="text-right" v-html="numberFormat(totalWon)"></td>
            <td colspan="2"></td>
          </tr>
          <tr style="font-weight: bold; font-size: 120%;">
            <td>เงินที่ควรจะมี</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(lottoBalance)"></td>
            <td colspan="2"></td>
          </tr>

          <tr>
            <td colspan="4" style="height: 35px;"></td>
          </tr>
          <tr>
            <td>ขาด/เกิน</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(totalOther)"></td>
            <td colspan="2"></td>
          </tr>
          <tr style="font-weight: bold; font-size: 120%;">
            <td>เงินที่มีจริง</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(realBalance)"></td>
            <td colspan="2"></td>
          </tr>

          <tr>
            <td colspan="4" style="height: 35px;"></td>
          </tr>

          <tr style="font-size: 120%;">
            <td>โอนกลับบัญชีกลาง</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(refundAmount)"></td>
            <td class="text-center">{{ saveRefundAmountBy }}</td>
            <td class="text-center"><small>{{ saveRefundNote }}</small></td>
          </tr>
        </tbody>
        <tfoot>
          <tr style="font-size: 180%;">
            <th>คงเหลือ</th>
            <th class="text-right font-weight-bold" v-html="numberFormat(grandTotal, '0')"></th>
            <td colspan="2"></td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div v-if="reportId">
      <div v-if="logs.length > 0">
        <span>บันทึกข้อความ</span>
        <table class="table table-sm table-striped">
          <tbody>
            <tr v-for="(item, index) in logs" :key="index">
              <td>
                <small>{{ item.logs.date }} โดย {{ item.logs.username }}</small> - <span class="text-info">{{ item.logs.message }}</span>
                <div v-if="item.logs.image" class="p-2">
                  <a :href="item.logs.image" target="_blank"><img :src="item.logs.image" style="max-width: 100%; width: 250px;"></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-between">
        <button class="btn btn-lg btn-info" @click="addMessage">บันทึกข้อความ</button>
        <button v-if="!isManager && isOwner && reportStatus.code === 'Recording'" class="btn btn-lg btn-success" @click="sendReport">ส่งรายงาน</button>
        <button v-if="isManager && reportStatus.code === 'PendingReview'" class="btn btn-lg btn-success" @click="confirmReport">ยืนยันข้อมูล</button>
      </div>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />

    <RecordAgentModal :data="agentData" :markets="MarketItems" :is-show="isShowAgentModal" @close="modalClosed" />
    <RecordOtherModal :data="otherData" :items="OtherItems" :is-show="isShowOtherModal" @close="modalClosed" />
    <RecordSummaryModal :data="summaryData" :is-show="isShowSummaryModal" @close="modalClosed" />
    <MessageModal :data="messageData" :is-show="isShowMessageModal" @close="modalClosed" />
  </div>
</template>
<script>
import AppConfig from '@/configs/app.config.js'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import moment from '@/helpers/moment'
import ConvStatus from '@/helpers/conv-status'

import RecordService from '@/services/RecordService'
import ReportService from '@/services/ReportService'
import TransactionService from '@/services/TransactionService.js'

import RecordAgentModal from './record/components/RecordAgentModal'
import RecordOtherModal from './record/components/RecordOtherModal'
import RecordSummaryModal from './record/components/RecordSummaryModal'
import MessageModal from '@/views/components/MessageModal'

import Swal from 'sweetalert2'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import _ from 'lodash'

export default {
  name: 'BranchDailyRecord',
  components: {
    RecordAgentModal,
    RecordOtherModal,
    RecordSummaryModal,
    MessageModal,
    flatPickr,
    Loading
  },
  data() {
    return {
      isLoading: false,
      date: this.$route.params?.date || moment().add(-5, 'h').format('YYYY-MM-DD'),
      Account: null,
      Websites: [],
      Others: [],
      OtherItems: [],
      Summary: null,
      Activities: [],
      MarketGroups: [],
      Markets: [],

      isShowAgentModal: false,
      agentData: null,

      isShowOtherModal: false,
      otherData: null,

      isShowSummaryModal: false,
      summaryData: null,

      isShowMessageModal: false,
      messageData: null,

      datepickerConfig: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        maxDate: moment().format("YYYY-MM-DD")
        // onChange: this.onChangeDate
      }
    }
  },
  computed: {
    isManager() {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      return /*LoggedInData?.userRole === 'Owner' ||*/ LoggedInData?.role === 'Manager'
    },

    isOwner() {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      return LoggedInData?.userRole === 'Owner'
    },

    MarketItems() {
      return this.Markets.map((market)=>{
        market['group'] = this.MarketGroups.find((group)=>{
          return group._id === market?.owners?.mGroupId
        })
        return market
      })
    },

    /**
     * เงินตั้งต้น
     */
    startAmount() {
      return this.Summary?.start?.amount || 0.00
    },
    /**
     * ยอดขาย
     */
    totalSale() {
      return this.Summary?.sales || 0.00
    },
    /**
     * ยอดจ่าย
     */
    totalWon() {
      return this.Summary?.won || 0.00
    },
    /**
     * เงินที่ควรจะมี
     */
    lottoBalance() {
      return this.Summary?.lottoBalance || 0.00
    },
    /**
     * ขาด/เกิน
     */
    totalOther() {
      return this.Summary?.other || 0.00
    },
    /**
     * เงินที่มีจริง
     */
    realBalance() {
      return this.Summary?.realBalance || 0.00
    },
    // balance() {
    //   return this.Summary?.balance || 0.00
    // },
    // total() {
    //   return this.Summary?.total || 0.00
    // },

    /**
     * โอนกลับบัญชีกลาง
     */
    refundAmount() {
      return this.Summary?.refund?.amount || 0.00
    },
    /**
     * คงเหลือ
     * @return {[type]} [description]
     */
    grandTotal() {
      return this.Summary?.grandTotal || 0.00
    },

    saveStartAmountBy() {
      return this.Summary?.start?.logs.by || '-'
    },
    saveRefundAmountBy() {
      return this.Summary?.refund?.logs.by || '-'
    },
    saveStartNote() {
      return this.Summary?.start?.note
    },
    saveRefundNote() {
      return this.Summary?.refund?.note
    },
    reportId() {
      return this.Summary?._id
    },
    reportStatus() {
      const status = ConvStatus.report(this.Summary?.status)
      console.log('status', status)
      return status
    },
    logs() {
      return this.Activities || []
    },
    AccountName() {
      return this.Account?.name || ''
    },
    AccountId() {
      return this.$route.params?.accountId || null
    }
  },
  watch: {
    date() {
      this.getRecord()
    }
  },
  methods: {
    getRecord() {
      this.isLoading = true
      RecordService.get(this.date, this.AccountId)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Account = response.data.Account
          this.Websites = response.data.Websites
          this.Others = response.data.Others
          this.Summary = response.data.Summary
          this.Activities = response.data.Activities
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        console.log('finally')
        this.isLoading = false
      })
    },
    getOtherItems() {
      TransactionService.gets('OtherItem').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.OtherItems = response.data
        }
      })
    },
    getMarketGroups() {
      TransactionService.gets('MarketGroup').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.MarketGroups = response.data
        }
      })
    },
    getMarkets() {
      TransactionService.gets('Market').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Markets = response.data
        }
      })
    },
    toggleAgentData(web, agent) {
      this.agentData = {web, agent, date: this.date}
      this.isShowAgentModal = true
    },
    addItem() {
      this.otherData = {
        _id: null,
        date: this.date,
        amount: {
          number: null
        },
        item_id: null,
        item_name: null,
        item_type: null,
        description: null,
        markets: null,
        options: null
      }
      this.isShowOtherModal = true
    },
    editItem(item) {
      console.log(item.owners)
      this.otherData = {
        _id: item._id,
        date: item.date,
        amount: item.amount,
        item_id: item.options.otherId,
        item_name: item.options.item_name,
        item_type: item.options.item_type,
        description: item.description,
        markets: item.markets,
        options: item.options
      }
      this.isShowOtherModal = true
    },
    deleteItem(item) {
      console.log(item)
      Swal.fire({
        title: 'ยืนยันลบ?',
        text: `${item.item_name} ${item.amount.number}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
        customClass: {
          actions: 'justify-content-around'
        }
      }).then((result) => {
        if(result.isConfirmed) {
          RecordService.delete(item._id, this.AccountId)
          .then((response)=>{
            console.log(response)
            if(response.status === 'success') {
              this.getRecord()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: e?.name || 'ผิดพลาด!',
              text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    editSummary(itemCode) {

      const names = {
        start: 'เงินตั้งต้น',
        refund: 'โอนเงินคืนบริษัท'
      }

      const prefix = {
        start: '',
        refund: '-'
      }

      this.summaryData = {
        _id: this.Summary?._id,
        code: itemCode,
        accountId: null,
        date: this.date,
        name: names[itemCode],
        prefix: prefix[itemCode],
        amount: Math.abs(this.Summary[itemCode]?.amount || 0),
        note: this.Summary[itemCode]?.note || null
      }
      this.isShowSummaryModal = true
    },
    modalClosed(needReload) {
      this.isShowAgentModal = false
      this.isShowOtherModal = false
      this.isShowSummaryModal = false
      this.isShowMessageModal = false
      if(needReload) {
        this.getRecord()
      }
    },
    numberFormat(number, defaultText='-', dec=2) {
      if(number==null || number==='-')
        return defaultText

      if(parseFloat(number)===0)
        return number

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },
    sendReport() {
      Swal.fire({
        title: 'ยืนยันส่งรายงาน!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
        customClass: {
          actions: 'justify-content-around'
        }
      }).then((result) => {
        if(result.isConfirmed) {
          ReportService.sendReport(this.Summary?._id)
          .then((response)=>{
            console.log(response)
            if(response.status === 'success') {
              this.getRecord()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ส่งรายงานเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            console.log(e)
            Swal.fire({
              title: e?.name || 'ผิดพลาด!',
              text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'ส่งรายงานไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    addMessage() {
      this.messageData = {
        owners: {
          accountId: this.Account._id,
          userId: null,
          summaryId: this.Summary._id,
          transactionId: null
        },
        message: ''
      }
      this.isShowMessageModal = true
    },
    confirmReport() {
      Swal.fire({
        title: 'ข้อมูลถูกต้อง!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
        customClass: {
          actions: 'justify-content-around'
        }
      }).then((result) => {
        if(result.isConfirmed) {
          ReportService.confirmReport(this.Summary?._id)
          .then((response)=>{
            console.log(response)
            if(response.status === 'success') {
              this.getRecord()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ยืนยันเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            console.log(e)
            Swal.fire({
              title: e?.name || 'ผิดพลาด!',
              text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'ยืนยันไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    agentGrandCommission(agentAmount) {
      const agentHold = agentAmount.agentHold || 0
      const gabAmount = agentAmount.gabAmount || 0
      const commission = agentAmount.commission || 0

      return agentHold + gabAmount + commission
    }
  },
  mounted() {
    this.getMarketGroups()
    this.getMarkets()
    this.getRecord()
    this.getOtherItems()
  }
}
</script>
<style lang="scss">
#daily-record {
  padding-bottom: 100px;
  position: relative;

  .badge {
    font-size: 150%;
  }

  .table {
    thead {
      font-size: 90%;
      tr {
        th {
          // font-weight: normal;
          vertical-align: middle;

          span {
            font-weight: bold;
            font-size: 110%;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }

  .swal2-actions {
    justify-content: space-around !important;
  }
}
</style>
